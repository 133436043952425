import React, { ComponentType } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  IconButton,
  FormHelperText,
  Stack,
  styled,
  useTheme,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ClearIcon from "@mui/icons-material/Clear";
import { useAppSelector } from "store/store";

interface FileUploadProps {
  fileAdded: boolean;
  fileName: string;
  actionText?: string;
  handleRemoveFile: () => void;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errors?: string | null;
  fieldName?: string;
  isRequired?: boolean;
  fileType?: string;
  helpText?: string;
}

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export const WithFileUpload = (props: FileUploadProps) => {
  const {
    fileAdded,
    fileName,
    actionText,
    handleRemoveFile,
    handleFileChange,
    errors,
    fieldName = "",
    isRequired = true,
    fileType = "application/pdf",
    helpText,
  } = props;
  const theme = useTheme();
  return (
    <>
      <Box>
        <Typography
          py="10px"
          fontWeight={400}
          fontSize={"1rem"}
          color={theme.palette.text.dark}
        >
          {fieldName}
          {fieldName && isRequired && <span style={{ color: "red" }}>*</span>}
        </Typography>
      </Box>
      <Box
        border="1px dashed"
        p={3}
        position="relative"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {fileAdded ? (
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{ height: "50px" }}
          >
            <Typography>{fileName}</Typography>
            {actionText !== "view" && (
              <IconButton onClick={handleRemoveFile} color="error">
                <ClearIcon />
              </IconButton>
            )}
          </Stack>
        ) : (
          <>
            <Button
              sx={{
                height: "50px",
                backgroundColor:  theme.palette.primary.buttonColor,
                "&:hover": {
                  backgroundColor:  theme.palette.primary.buttonColor,
                },
              }}
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
            >
              Upload file
              <VisuallyHiddenInput
                accept={fileType}
                type="file"
                onChange={handleFileChange}
              />
            </Button>
          </>
        )}
      </Box>
      {helpText && (
        <span style={{ opacity: "0.8", textAlign: "center", float: "right" }}>
          {helpText}{" "}
          <span
            style={{
              color: "#ef5350",
              fontSize: ".7rem",
              display: "inline-block",
            }}
          >
            {" "}
            (Maximum file size 10mb){" "}
          </span>
        </span>
      )}
      {errors && (
        <Box>
          <FormHelperText sx={{ color: "red" }}>{errors}</FormHelperText>
        </Box>
      )}
    </>
  );
};

export default WithFileUpload;
