import { combineReducers } from "@reduxjs/toolkit";
import userSlice from "./user.slice";
import customizationReducer from "features/theme/customizationReducer";
import actionSlice from "./action.slice";

const rootReducer = combineReducers({
  user: userSlice,
  customization: customizationReducer,
  action: actionSlice,

});

export default rootReducer;
