import { Box, Button, Stack, useTheme } from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useAppSelector } from "store/store";

const FormLayout = ({
  handleSubmit,
  children,
  backButtonText,
  submitButtonText,
  backButtonClick,
  skipButtonClick,
  isBackButton,
  scrollable,
  disableSave = false,
  isSaveButton = true,
  isSkip = false,
  isUpdateButton = false,
  updateButtonClick
}: FormTypes) => {
  const theme = useTheme();
  return (
    <Stack
      component={"form"}
      flexGrow={1}
      height={"100%"}
      width={"100%"}
      noValidate
      onSubmit={handleSubmit}
      justifyContent={"space-between"}
    >
      <Box
        maxHeight={"calc(75vh)"}
        width={"100%"}
        sx={{
          overflowY: scrollable ? scrollable : "auto",
          scrollbarWidth: "thin",
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
            borderRadius: "4px",
          },
        }}
        // px={scrollable ? 0 : "30px"}
      >
        {children}
      </Box>
      {/* <PerfectScrollbar
        component="div"
        style={{
          // height: 'calc(100vh - 280px)',
          height: "calc(80vh - 150px)",
          padding: '0 20px'
        }}
      >
        {children}
        </PerfectScrollbar> */}
      <Box sx={{ pt: 2 }} display={"flex"} justifyContent={"right"}>
        <Box sx={{ mr: 2 }}>
          {isSkip && (
            <Button
              variant="contained"
              sx={{
                backgroundColor: "lightgray",
                color: "#121926",
                "&:hover": {
                  backgroundColor: "lightgray",
                },
              }}
              onClick={skipButtonClick}
            >
              {"Skip"}
            </Button>
          )}
        </Box>
        <Box sx={{ mr: 2 }}>
          {isBackButton && (
            <Button
              variant="contained"
              sx={{
                backgroundColor: "lightgray",
                color: "#121926",
                "&:hover": {
                  backgroundColor: "lightgray",
                },
              }}
              onClick={backButtonClick}
            >
              {backButtonText || "Back"}
            </Button>
          )}
        </Box>
          {isUpdateButton && (
        <Box sx={{ mr: 2 }}>
            <Button
              variant="contained"
              // disabled={disableSave}
              type="submit"
              sx={{
                fontSize:{
                  xs: "13px",
                }
              }}
              onClick={updateButtonClick}
            >
              {"update"}
            </Button>
        </Box>
          )}
      {/* </Box> */}
        <Box>
          {isSaveButton && (
            <Button
              variant="contained"
              disabled={disableSave}
              type="submit"
              sx={{
                fontSize:{
                  xs: "13px",
                }
              }}
            >
              {submitButtonText || "Save & Proceed"}
            </Button>
          )}
        </Box>
      </Box>
    </Stack>
  );
};

type FormTypes = {
  handleSubmit?: any;
  children: any;
  submitButtonText?: string;
  backButtonText?: string;
  isBackButton?: boolean;
  isSubmitButton?: boolean;
  backButtonClick?: any;
  skipButtonClick?: any;
  scrollable?: any;
  disableSave?: boolean;
  isSaveButton?: boolean;
  isSkip?: boolean;
  isUpdateButton?:boolean;
  updateButtonClick?:any
};
export default FormLayout;
