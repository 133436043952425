import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAppSelector } from "store/store";
import { Roles } from "helpers/enum.helpers";

const GuestRoute = () => {
    const auth = useAppSelector((state) => state.user.token);
    const token = sessionStorage.getItem("token")
    console.log("GuestRoute", token);
    let location = useLocation();
    return  !!auth ? (
        <Navigate to={`/dashboard`} state={{ from: location }} replace />
    ) : (
      <Outlet />
    );
  };

  

export default GuestRoute;