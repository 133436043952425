const admin = {
  id: `dashboard`,
  title: `Dashboard`,
  type: `group`,
  children: [
    {
      id: `default`,
      title: `Dashboard`,
      type: `item`,
      url: `/dashboard`,
      icon: `gridView`,
      breadcrumbs: false,
      href: false,
      activeItem: [`/dashboard`],
    },
     {
      id: `Enquiries`,
      title: `Enquiries`,
      type: `item`,
      url: `/enquires`,
      icon: `policy`,
      breadcrumbs: false,
      href: false,
      activeItem: [`/enquires`,`/enquiry/edit`,`/enquiry/view`],
    },
    {
      id: `Brokers`,
      title: `Brokers`,
      type: `item`,
      url: `/brokers`,
      icon: `group`,
      breadcrumbs: false,
      href: false,
      activeItem: [`/brokers`],
    },
    {
      id: `reconciliation`,
      title: `Reconciliation`,
      type: `item`,
      url: `/reconciliation`,
      icon: `Reconciliation`,
      breadcrumbs: false,
      href: false,
      activeItem: [
        `/reconciliation`,
      ],
    },
    {
      id: `reports`,
      title: `Reports`,
      type: `item`,
      url: `/reports`,
      icon: `report`,
      breadcrumbs: false,
      href: false,
      activeItem: [`/reports`,'/reports/subscription-report'],
    },
    {
      id: `Settings`,
      title: `Settings`,
      type: `item`,
      url: `/settings`,
      icon: `setting`,
      breadcrumbs: false,
      href: false,
      activeItem: [`/settings`, `/settings/masters` , `/settings/plans` , `/settings/features`],
    },  
  ],
};

export default admin;
