export enum QuoteStatus {
  INITIATED = "INITIATED",
  APPROVED = "APPROVED",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED"
}

export enum Roles {
  SuperAdmin= "Super Admin",
}

export enum PaymentStatus {
  Paid ="Paid", 
  Scheduled ="Scheduled", 
  PayNow ="Pay now"
}

