import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAppSelector } from "store/store";

const ProtectedUserRoute = () => {
  const auth = useAppSelector((state) => state.user.token);
  const token = sessionStorage.getItem("token")
  console.log("ProtectedUserRoute", token);
    let location = useLocation();
    return !!auth   ? (
      <Outlet />
    ) : (
      <Navigate to="/login" state={{ from: location }} replace />
    );
  };


  export default ProtectedUserRoute