import * as yup from "yup";
import { PhoneNumberUtil } from "google-libphonenumber";
import dayjs from "dayjs";
import { vin } from "@form-validation/validator-vin";

const weightRegex = RegExp(/^(?!00000)[0-9]+(?:\.[0-9]+)?(?:\s*(kg|lbs))?$/);

const urlPattern =
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

const SSNPattern = /^\d{3}-?\d{2}-?\d{4}$/;

const validTLDs = [
  "com",
  "org",
  "net",
  "in",
  "gov",
  "edu",
  "mil",
  "int",
  "info",
  "biz",
  "name",
  "museum",
  "areo",
  "coop",
  "pro",
  "jobs",
  "mobi",
  "tel",
  "travel",
]; // Add more TLDs as needed
const tldRegex = validTLDs.join("|");

const usZipRegex = RegExp(/^(?!00000)[0-9]{5}(?:-[0-9]{4})?$/);
const emailRegex = RegExp(
  new RegExp(`^[\\w.+-]+@[a-zA-Z\\d.-]+\\.(${tldRegex})(?:\\.[a-zA-Z]{2,})?$`)
);

const domainRegex = new RegExp(
  '^[a-zA-Z-]+$'
);

const passwordPattern =
  /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])([a-zA-Z0-9@$!%*?&]{8,16})$/;

const VINRegex = RegExp(/^[A-Za-z0-9]{17}$/);

function getErrorsFromValidationError(validationError: any) {
  const FIRST_ERROR = 0;
  return validationError.inner.reduce((errors: any, error: any) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR],
    };
  }, {});
}

function handleErrorMeg(msg: any, schema: any) {
  try {
    schema.validateSync(msg, { abortEarly: false });
    return {};
  } catch (error) {
    return getErrorsFromValidationError(error);
  }
}

const phoneNumberUtil = PhoneNumberUtil.getInstance();

// Custom Yup validation method for phone number validation
const phoneSchema = (isRequired: boolean, errorText: string) =>
  yup.string().test("phone", errorText, function (value) {
    if (!value) return true; // Allow empty values
    if (value.length <= 3 && !isRequired) {
      return true;
    }
    try {
      const phoneNumber = phoneNumberUtil.parse(value);
      return phoneNumberUtil.isValidNumber(phoneNumber);
    } catch (error) {
      return false;
    }
  });

const vinSchema = (isRequired: boolean, errorText: string) =>
  yup.string().test("vin", errorText, function (value) {
    if (!value) return true;
    if (value.length && !isRequired) {
      return true;
    }
    try {
      const res2 = vin().validate({
        value: value,
        options: {
          message: "Invalid vin",
        },
      });
      return res2.valid;
    } catch (err) {
      return false;
    }
  });

export let LoginVerificationSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .matches(emailRegex, "Invalid email id")
    .required("Email id is required"),
  password: yup.string().required("Password is required"),
});

export function LoginValidation(values: any) {
  return handleErrorMeg(values, LoginVerificationSchema);
}

export let EmailSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .matches(emailRegex, "Invalid email id")
    .required("Email id is required"),
  //password: yup.string().required("Password is required"),
});

export function EmailValidation(values: any) {
  return handleErrorMeg(values, EmailSchema);
}

export function setPasswordValidation(values: any) {
  return handleErrorMeg(values, setPasswordSchema);
}

export function resetPasswordValidation(values: any) {
  return handleErrorMeg(values, resetPasswordSchema);
}

export function changePasswordValidation(values: any) {
  return handleErrorMeg(values, changePasswordSchema);
}

export const changePasswordSchema = yup.object().shape({
  oldPassword: yup
    .string()
    //.matches(passwordPattern, "Password Should Contain 8 Characters ( 1 Upper Case , 1 Lower Case , 1 Special Character (@, $, !, %, *, ?, or &) and 1 Numeric Value )")
    .min(8, "Password Must Be At Least 8 Characters")
    .max(16, "Password Must Be aAt Most 32 Characters")
    .required("Old Password is Required"),
  password: yup
    .string()
    .matches(
      passwordPattern,
      "Password Should Contain 8 Characters ( 1 Upper Case , 1 Lower Case , 1 Special Character (@, $, !, %, *, ?, or &) and 1 Numeric Value )"
    )
    .required("Password is Required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords Must Match"),
});

export const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .matches(
      passwordPattern,
      "Password Should Contain 8 Characters ( 1 Upper Case , 1 Lower Case , 1 Special Character (@, $, !, %, *, ?, or &) and 1 Numeric Value )"
    )
    .required("Password is Required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords Must Match"),
});

export const setPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .matches(
      passwordPattern,
      "Password Should Contain 8 Characters ( 1 Upper Case , 1 Lower Case , 1 Special Character (@, $, !, %, *, ?, or &) and 1 Numeric Value )"
    )
    .required("Password is Required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords Must Match"),
});

const createClientSchema = yup.object().shape({
  customer_type: yup
    .object()
    .required("Cutomer Type is required")
    .typeError("Cutomer Type is required"),
  prefix: yup.string().required("Prefix is required"),
  first_name: yup.string().required("First name is required"),
  middle_name: yup.string().nullable().optional(),
  last_name: yup.string().required("Last name is required"),
  suffix: yup.string().nullable().optional(),
  gender: yup.string().required("Gender is required"),
  dob: yup.date().required("Date of Birth is required"),
  marital_status: yup.string().required("Marital status is required"),
  email: yup.string().trim().required("Email is required"),
  phone_number: yup.string().required("Phone is required"),
  address: yup.string().required("Address is required"),
  date_terminated: yup.date().nullable().optional(),
  date_employed: yup.date().nullable().optional(),
  social_security: yup
    .string()
    .nullable()
    .optional()
    .test("is-valid-ssn", "Invalid ssn", (value) => {
      if (value) {
        return yup.string().matches(SSNPattern).isValidSync(value);
      }
      return true; // Allow empty value
    }),

  // second insured
  second_prefix: yup.string().when("customer_type", {
    is: (value: any) => value?.name == "Two Individuals",
    then: () => yup.string().required("Prefix is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  second_first_name: yup.string().when("customer_type", {
    is: (value: any) => value?.name == "Two Individuals",
    then: () => yup.string().required("First name is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  second_middle_name: yup.string().optional(),
  second_last_name: yup.string().when("customer_type", {
    is: (value: any) => value?.name == "Two Individuals",
    then: () => yup.string().required("Last name is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  second_suffix: yup.string().nullable().optional(),
  second_gender: yup.string().when("customer_type", {
    is: (value: any) => value?.name == "Two Individuals",
    then: () => yup.string().required("Gender is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  second_dob: yup.date().when("customer_type", {
    is: (value: any) => value?.name == "Two Individuals",
    then: () => yup.string().required("Date of Birth is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  second_marital_status: yup.string().when("customer_type", {
    is: (value: any) => value?.name == "Two Individuals",
    then: () => yup.string().required("Marital status is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  // second_address: yup.string()
  // .when('customer_type', {
  //   is: (value: any) => value?.name == 'Two Individuals',
  //   then: () => yup.string().required("Address is required"),
  //   otherwise: () => yup.string().nullable().optional(),
  // }),
  second_date_terminated: yup.date().nullable().optional(),
  second_date_employed: yup.date().nullable().optional(),
  second_social_security: yup.string().nullable().optional(),

  // company validation
  company_name: yup.string().when("customer_type", {
    is: (value: any) => value?.name == "Corporation",
    then: () => yup.string().required("Company Name is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  dba_name: yup.string().nullable().optional(),
  tax_id: yup.string().when("customer_type", {
    is: (value: any) => value?.name == "Corporation",
    then: () => yup.string().required("Tax ID is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  employer_registration: yup.string().nullable().optional(),
  date_business_started: yup.string().nullable().optional(),
  incorporation_state: yup.string().when("customer_type", {
    is: (value: any) => value?.name == "Corporation",
    then: () => yup.string().required("Incorporation State is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  second_email: yup.string().when("customer_type", {
    is: (value: any) =>
      value?.name == "Two Individuals" || value?.name == "Corporation",
    then: () => yup.string().required("Email is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  second_phone_number: yup.string().when("customer_type", {
    is: (value: any) =>
      value?.name == "Two Individuals" || value?.name == "Corporation",
    then: () => yup.string().required("Phone is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  second_address: yup.string().when("customer_type", {
    is: (value: any) => value?.name == "Corporation",
    then: () => yup.string().required("Address is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
});

export function createClientValidation(values: any) {
  return handleErrorMeg(values, createClientSchema);
}

export let policyCreateValidationSchema = yup.object().shape({
  policyId: yup
    .string()
    .trim()
    .matches(emailRegex, "Invalid email id")
    .required("Email id is required"),
  policyType: yup.string().required("Password is required"),
});

export function policyCreateValidation(values: any) {
  return handleErrorMeg(values, policyCreateValidationSchema);
}

export let emailModalSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .matches(emailRegex, "Invalid email id")
    .required("Email id is required"),
  notes: yup.string().optional(),
  is_default: yup.boolean().optional(),
});

export let phoneModalSchema = yup.object().shape({
  phone_type: yup.string().required("Phone type is required"),
  note: yup.string().optional(),
  phone: phoneSchema(true, "Invalid Phone Number").required(
    "Phone is required"
  ),
  area_code: yup.number().required("Area code is required"),
  is_default: yup.boolean().optional(),
});

export let addressModalSchema = yup.object().shape({
  address_type: yup
    .string()
    .required("Please select your address type")
    .typeError("Please select your address type"),
  address_line_1: yup.string().trim().required("Address Line 1 is required"),
  address_line_2: yup.string().nullable(),
  city: yup.string().trim().required("City is required"),
  state: yup
    .string()
    .required("Please select your state")
    .typeError("Please select your state"),
  zip_code: yup
    .string()
    .matches(usZipRegex, "Invalid Zip Code")
    .required("Zipcode is required"),
  is_default: yup.boolean().optional(),
});

export function AddressModalValidation(values: any) {
  return handleErrorMeg(values, addressModalSchema);
}

export function PhoneModalValidation(values: any) {
  return handleErrorMeg(values, phoneModalSchema);
}

export function EmailModalValidation(values: any) {
  return handleErrorMeg(values, emailModalSchema);
}
export let createCompanyValidationSchema = yup.object().shape({
  company_name: yup.string().required("Company name is required"),
  dba_name: yup.string().required("DBA name is required"),
  taxId: yup.string().required("Tax ID is required"),
  employee_registration: yup
    .string()
    .required("Employee Registration is required"),
  start_date: yup.date().required("Start date is required"),
  state: yup.string().required("State is required"),
});

export function createCompanyValidation(values: any) {
  return handleErrorMeg(values, createCompanyValidationSchema);
}

export let createBankValidationSchema = yup.object().shape({
  bank: yup.string().required("Bank is required"),
  routing_no: yup.number().required("Routing no is required"),
  account_no: yup.number().required("Account no is required"),
  account_type: yup.string().required("Account type is required"),
  starting_check_no: yup.string().required("Starting check no is required"),
  name_of_account: yup.string().required("Account name is required"),
  entity_type: yup.string().required("Entity type is required"),
  address: yup.string().required("Address is required"),
});

export function createBankValidation(values: any) {
  return handleErrorMeg(values, createBankValidationSchema);
}

export let createDocumentValidationSchema = yup.object().shape({
  name: yup.string().required("File Name is required"),
  reason: yup.string().nullable(),
  path: yup.string().required("File is required").typeError("File is required"),
  file_type: yup
    .string()
    .required("File Type is required")
    .typeError("File Type is required"),
});

export function createDocumentValidation(values: any) {
  return handleErrorMeg(values, createDocumentValidationSchema);
}

export let createEmploymentValidationSchema = yup.object().shape({
  customer_type: yup
    .object()
    .required("Cutomer Type is required")
    .typeError("Cutomer Type is required"),
  occupation: yup
    .object()
    .required("Occupation is required")
    .typeError("Occupation is required"),
  applicants_employer: yup.string().when("occupation", {
    is: (value: any) => value?.name == "Employed" || value?.name == "Retired",
    then: () =>
      yup
        .string()
        .required("Applicant's Employer is required")
        .typeError("Applicant's Employer is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  job_title: yup.string().when("occupation", {
    is: (value: any) => value?.name == "Employed" || value?.name == "Retired",
    then: () =>
      yup
        .string()
        .required("Job Title is required")
        .typeError("Job Title is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  address_line_1: yup.string().when("occupation", {
    is: (value: any) => value?.name == "Employed" || value?.name == "Retired",
    then: () =>
      yup
        .string()
        .required("Address 1 is required")
        .typeError("Address 1 is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  address_line_2: yup.string().nullable().optional(),
  city: yup.string().when("occupation", {
    is: (value: any) => value?.name == "Employed" || value?.name == "Retired",
    then: () =>
      yup.string().required("City is required").typeError("City is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  state: yup.string().when("occupation", {
    is: (value: any) => value?.name == "Employed" || value?.name == "Retired",
    then: () =>
      yup.string().required("State is required").typeError("State is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  zip_code: yup.string().when("occupation", {
    is: (value: any) => value?.name == "Employed" || value?.name == "Retired",
    then: () =>
      yup
        .string()
        .matches(usZipRegex, "Invalid Zip Code")
        .required("Zip Code is required")
        .typeError("Zip Code is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  work_phone: yup.string().when("occupation", {
    is: (value: any) => value?.name == "Employed" || value?.name == "Retired",
    then: () =>
      phoneSchema(true, "Invalid Mobile Number").required(
        "Work Phone is required"
      ),
    otherwise: () => yup.string().nullable().optional(),
  }),
  industry: yup.string().when("occupation", {
    is: (value: any) => value?.name == "Employed" || value?.name == "Retired",
    then: () =>
      yup
        .string()
        .required("Industry is required")
        .typeError("Industry is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  years_with_company: yup.number().typeError("Years with Company should be number").when("occupation", {
    is: (value: any) => value?.name == "Employed" || value?.name == "Retired",
    then: () =>
      yup
        .number()
        .required("Years with Company is required")
        .typeError("Years with Company should be number"),
    otherwise: () => yup.number().nullable().optional().typeError("Years with Company should be number"),
  }),
  years_with_previous_employer: yup.number().optional().nullable().typeError("Years should be number"),

  // second insured
  second_occupation: yup.string().when("customer_type", {
    is: (value: any) => value?.name == "Two Individuals",
    then: () =>
      yup
        .object()
        .required("Occupation is required")
        .typeError("Occupation is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  second_applicants_employer: yup
    .string()
    .when(["customer_type", "second_occupation"], {
      is: (value: any, secondOccupation: any) =>
        value?.name == "Two Individuals" &&
        (secondOccupation?.name == "Employed" ||
          secondOccupation?.name == "Retired"),
      then: () => yup.string().required("Applicant's Employer is required"),
      otherwise: () => yup.string().nullable().optional(),
    }),
  second_job_title: yup.string().when("customer_type", {
    is: (value: any, secondOccupation: any) =>
      value?.name == "Two Individuals" &&
      (secondOccupation?.name == "Employed" ||
        secondOccupation?.name == "Retired"),
    then: () => yup.string().required("Job Title is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  second_address_line_1: yup.string().when("customer_type", {
    is: (value: any, secondOccupation: any) =>
      value?.name == "Two Individuals" &&
      (secondOccupation?.name == "Employed" ||
        secondOccupation?.name == "Retired"),
    then: () => yup.string().required("Address 1 is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  second_address_line_2: yup.string().nullable().optional(),
  second_city: yup.string().when("customer_type", {
    is: (value: any, secondOccupation: any) =>
      value?.name == "Two Individuals" &&
      (secondOccupation?.name == "Employed" ||
        secondOccupation?.name == "Retired"),
    then: () => yup.string().required("City is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  second_state: yup.string().when("customer_type", {
    is: (value: any, secondOccupation: any) =>
      value?.name == "Two Individuals" &&
      (secondOccupation?.name == "Employed" ||
        secondOccupation?.name == "Retired"),
    then: () => yup.string().required("State is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  second_zip_code: yup.string().when("customer_type", {
    is: (value: any, secondOccupation: any) =>
      value?.name == "Two Individuals" &&
      (secondOccupation?.name == "Employed" ||
        secondOccupation?.name == "Retired"),
    then: () =>
      yup
        .string()
        .matches(usZipRegex, "Invalid Zip Code")
        .required("Zip Code is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  second_work_phone: phoneSchema(true, "Invalid Mobile Number").when(
    "customer_type",
    {
      is: (value: any, secondOccupation: any) =>
        value?.name == "Two Individuals" &&
        (secondOccupation?.name == "Employed" ||
          secondOccupation?.name == "Retired"),
      then: () => yup.string().required("Work Phone is required"),
      otherwise: () => yup.string().nullable().optional(),
    }
  ),
  second_industry: yup.string().when("customer_type", {
    is: (value: any, secondOccupation: any) =>
      value?.name == "Two Individuals" &&
      (secondOccupation?.name == "Employed" ||
        secondOccupation?.name == "Retired"),
    then: () => yup.string().required("Industry is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  second_years_with_company: yup.number().typeError("Years should be in numbers").when("customer_type", {
    is: (value: any, secondOccupation: any) =>
      value?.name == "Two Individuals" &&
      (secondOccupation?.name == "Employed" ||
        secondOccupation?.name == "Retired"),
    then: () => yup.number().required("Years with Company is required").typeError("Years should be in numbers"),
    otherwise: () => yup.number().nullable().optional().typeError("Years should be in numbers"),
  }),
  second_years_with_previous_employer: yup.number().optional().nullable().typeError("Years should be in numbers"),
});

export function createEmploymentValidation(values: any) {
  return handleErrorMeg(values, createEmploymentValidationSchema);
}

const createDriverSchema = yup.object().shape({
  same_as_applicant: yup.boolean().optional(),
  prefix: yup.string().required("Prefix is required"),
  first_name: yup.string().required("First name is required"),
  middle_name: yup.string().optional(),
  last_name: yup.string().required("Last name is required"),
  suffix: yup.string().nullable().optional(),
  gender: yup.string().required("Gender is required"),
  dob: yup.date().required("Date of Birth is required"),
  marital_status: yup.string().required("Marital status is required"),
  email: yup.string().required("Email is required"),
  occupation: yup.string().required("Occupation is required"),
  //driver_status: yup.string().required("Driver status is required"),
  military_status: yup.string().required("Military status is required"),
  //is_licensed_driver: yup.string().required("Licensed driver is required"),
  date_licensed: yup.date().required("License Date required"),
  // .test(
  //   "SameDate",
  //   "License Expiry Date and Date of licensed must not be equal",
  //   function (value) {
  //     const { date_licensed } = this.parent;
  //     return !dayjs(value).isSame(date_licensed);
  //   }
  // )
  // .min(
  //   yup.ref("date_licensed"),
  //   "License Expiry Date can't be before Date of Licensed"
  // ),

  license_exp_date: yup
    .date()
    .required("License Exp Date required")
    .test(
      "notBeforeDateLicensed",
      "License Expiry Date can't be before or same as Date of Licensed",
      function (value) {
        const dateLicensed = this.parent.date_licensed;
        if (!dateLicensed) return true;
        return (
          !dayjs(value).isSame(dateLicensed) &&
          !dayjs(value).isBefore(dateLicensed)
        );
      }
    ),
  license_number: yup.string().required("License number is required"),
  state_licensed: yup
    .object()
    .required("License state is required")
    .typeError("License state is required"),
  tlc_license_number: yup.string().nullable().optional(),
  tlc_exp_date: yup.date().nullable().optional(),
  total_accidental_conviction_point: yup
    .string()
    .required("Conviction/Accident Points is required"),
});

export function createDriverValidation(values: any) {
  return handleErrorMeg(values, createDriverSchema);
}

export const CreateVehicleBasicValidationShema = yup.object().shape({
  vehicle_identification_number: yup
    .string()
    .typeError("Vehicle Identification Number is required")
    .required("Vehicle Identification Number is required"),
  vehicle_class: yup.string().required("Vehicle Class is required"),
  fuel_type: yup.string().required("Fuel Type is required"),
  vehicle_make: yup
    .string()
    .trim()
    .max(50, "Max 50 characters")
    .required("Vehicle Make is required"),
  vehicle_model: yup
    .string()
    .trim()
    .max(50, "Max 50 characters")
    .required("Vehicle Model is required"),
  vehicle_year: yup
    .object()
    .required("Vehicle Year is required")
    .typeError("Vehicle Year is required"),
  weight: yup
    .string()
    .trim()
    .matches(weightRegex, "Invalid weight")
    .max(50, "Max 50 characters")
    .required("Weight is required"),
  safety_rating: yup
    .string()
    .trim()
    .matches(/^(?!0)/, "Invalid Safety Rating")
    .required("Safety Rating is required"),
  seating_capacity: yup
    .string()
    .trim()
    .matches(/^(?!0)/, "Invalid Seating Capacity")
    .required("Seating Capacity is required"),
  vehicle_license_number: yup
    .string()
    .trim()
    .max(50, "Max 50 characters")
    .matches(/^(?!0)(?!1111)/, "Invalid FHV Vehicle License Number")
    .required("FHV Vehicle License Number is required"),
});

export function CreateVehicleBasicValidation(values: any) {
  return handleErrorMeg(values, createVehicleSchema);
}

export const createVehicleSchema = yup.object().shape({
  endorsement_date: yup
    .date()
    .optional()
    .nullable()
    .typeError("Endorsement Date is required"),
  vin: yup
    .string()
    .max(17, "Invalid Identification Number(VIN)")
    .matches(VINRegex, "Invalid Identification Number(VIN)")
    .required("Identification Number(VIN) is required"),
  make: yup.object().required("Make is required"),
  // model: yup.string().required("Model is required"),
  year: yup.object().required("Year is required").typeError("Year is required"),
  number_plate: yup.string().required("Number Plate is required"),
  shl_permit: yup.object().required("SHL Permit is required"),
  state_registered: yup
    .object()
    .required("State Registered is required")
    .typeError("State Registered is required"),
  body_type: yup
    .object()
    .required("Body Type is required")
    .typeError("Body Type is required"),
  vehicle_usage: yup
    .object()
    .required("Vehicle Usage is required")
    .typeError("Vehicle Usage is required"),
  performance: yup
    .object()
    .required("Performance is required")
    .typeError("Performance is required"),
  territory: yup
    .object()
    .required("Territory is required")
    .typeError("Territory is required"),
  comp_symbol: yup
    .object()
    .required("Comp Symbol is required")
    .typeError("Comp Symbol is required"),
  collison_symbol: yup
    .object()
    .required("Collision Symbol is required")
    .typeError("Collision Symbol is required"),
  cylinders: yup
    .object()
    .required("Cylinders is required")
    .typeError("Cylinders is required"),
  horse_power: yup.string().required("Horse Power is required"),
  fuel_type: yup.string().required("Fuel Type is required"),
  purchase_date: yup
    .date()
    .required("Purchase Date is required")
    .typeError("Purchase Date is required"),
  class_code: yup
    .object()
    .required("Class Code is required")
    .typeError("Class Code is required"),
  seating_capacity: yup.string().required("Seating Capacity is required"),
  reg_exp_date: yup
    .date()
    .required("Reg Exp Date is required")
    .typeError("Reg Exp Date is required"),
  diamond_exp_date: yup
    .date()
    .required("Diamond Exp Date is required")
    .typeError("Diamond Exp Date is required"),
  odometer: yup.string().required("Odameter is required"),
  vehicle: yup
    .object()
    .required("Vehicle is required")
    .typeError("Vehicle is required"),
});

export let createPolicyInfoValidationSchema = yup.object().shape({
  policy_state: yup
    .object()
    .required("State is required")
    .typeError("State is required"),
  policy_number: yup.string().required("Policy Number is required"),
  insurance_company: yup
    .object()
    .required("Insurance Company is required")
    .typeError("Insurance Company is required"),
  carier_status: yup
    .string()
    .required("Carrier Status is required")
    .typeError("Carrier Status is required"),
  policy_type: yup
    .string()
    .required("Policy Type is required")
    .typeError("Policy Type is required"),
  type: yup.string().required("Type is required").typeError("Type is required"),
  policy_status: yup
    .string()
    .required("Status is required")
    .typeError("Status is required"),
  policy_term: yup
    .string()
    .required("Policy Term is required")
    .typeError("Policy Term is required"),
  effective_date: yup.date().required("Effective Date is required"),
  expiration_date: yup
    .date()
    .min(
      [yup.ref("effective_date"), new Date()],
      "Expiration Date must be after the Effective Date and not before today"
    )
    .required("Expiration Date is required")
    .typeError("Expiration Date is required"),
  // state: yup.string().required("State is required"),
});

export function createPolicyInfoValidation(values: any) {
  return handleErrorMeg(values, createPolicyInfoValidationSchema);
}

export let createPolicyScheduleValidationSchema = yup.object().shape({
  premium: yup.string().required("Premium is required"),
  number_of_payments: yup.string().required("Number of payment is required"),
  premium_deposite: yup
    .string()
    .optional()
    .nullable()
    .test(
      "IsLessThanPremium",
      "Premium deposite must be less than premium",
      function (value: any) {
        const { premium } = this.parent;
        return !(parseInt(premium) < parseInt(value));
      }
    ),
  monthly_deposit: yup
    .string()
    .required("Monthly payment is required")
    .test(
      "IsLessThanPremium",
      "Monthly payment must be less than premium",
      function (value: any) {
        const { premium } = this.parent;
        return !(parseInt(premium) < parseInt(value));
      }
    )
    .test(
      "AmountExceeded",
      "Sum of monthly premium and deposite should be less than Premium",
      function (value: any) {
        const { premium, premium_deposite } = this.parent;
        const totalAmount = parseInt(premium || 0);
        const deposite = parseInt(premium_deposite || 0);
        const due = parseInt(value || 0);
        if (deposite + due > totalAmount) {
          return false;
        }
        return true;
      }
    ),
  installment_charge: yup.string().required("Installment Charge is required"),
  start_date: yup.date().required("Start Date is required"),
});

export function createPolicyScheduleValidation(values: any) {
  return handleErrorMeg(values, createPolicyScheduleValidationSchema);
}

export let FeesModalValidationSchema = yup.object().shape({
  surplus_lines_tax: yup.string().optional().nullable(),
  second_injury_fund: yup.string().optional().nullable(),
  cancel_fee: yup.string().optional().nullable(),
  excess_line_tax: yup.string().optional().nullable(),
});

export function FeesModalValidation(values: any) {
  return handleErrorMeg(values, FeesModalValidationSchema);
}

export let ClientPaymentModalValidationSchema = yup.object().shape({
  amount_received: yup
    .number()
    .required("Amount Received is required")
    .typeError("Amount Received is required"),
  payment_method: yup.object().required("Payment Method is required").typeError("Payment Method is required"),
  check: yup.string().when("payment_method", {
    is: (value: any) => {
      return value && value?.name === "CHEQUE";
    },
    then: () => yup.string().required("Cheque Number is required"),
    otherwise: () => yup.string().nullable(),
  }),
  amount_in_words: yup.string().when("payment_method", {
    is: (value: any) => {
      return value && value?.name === "CHEQUE";
    },
    then: () => yup.string().required("Amount in Words is required"),
    otherwise: () => yup.string().nullable(),
  }),
  pay_to_order_of: yup.string().when("payment_method", {
    is: (value: any) => {
      return value && value?.name === "CHEQUE";
    },
    then: () => yup.string().required("Pay to Order of is required"),
    otherwise: () => yup.string().nullable(),
  }),
  print_draft_date: yup.string().when("payment_method", {
    is: (value: any) => {
      return value && value?.name === "CHEQUE";
    },
    then: () => yup.date()
      .required("Print Draft Date is required")
      .test(
        "notBeforeDateLicensed",
        "Print Draft Date can't be past Date",
        function (value) {
          if (!value) return true;
          return (
            // !dayjs().add(3, "month").isAfter(value) &&
            !dayjs().add(6, "month").isBefore(value)
          );
        }
      ),
    otherwise: () => yup.string().nullable(),
  }),
  signature: yup.string().when("payment_method", {
    is: (value: any) => {
      return value && value?.name === "CHEQUE";
    },
    then: () => yup.string().required("Signature of is required"),
    otherwise: () => yup.string().nullable(),
  }),
  confirm: yup.string().optional().nullable(),
  reason: yup.string().optional().nullable(),
});


export function ClientPaymentModalValidation(values: any) {
  return handleErrorMeg(values, ClientPaymentModalValidationSchema);
}

export let CarrierPaymentModalValidationSchema = yup.object().shape({
  amount: yup
    .number()
    .required("Amount Received is required")
    .typeError("Amount Received is required"),
  payment_method: yup.object().required("Payment Method is required").typeError("Payment Method is required"),
  date_paid: yup.date().required("Date Paid is required"),
  check: yup.string().when("payment_method", {
    is: (value: any) => {
      return value && value?.name === "CHEQUE";
    },
    then: () => yup.string().required("Cheque Number is required"),
    otherwise: () => yup.string().nullable(),
  }),
  amount_in_words: yup.string().when("payment_method", {
    is: (value: any) => {
      return value && value?.name === "CHEQUE";
    },
    then: () => yup.string().required("Amount in Words is required"),
    otherwise: () => yup.string().nullable(),
  }),
  print_draft_date: yup.string().when("payment_method", {
    is: (value: any) => {
      return value && value?.name === "CHEQUE";
    },
    then: () => yup.date()
      .required("Print Draft Date is required")
      .test(
        "notBeforeDateLicensed",
        "Print Draft Date can't be past Date",
        function (value) {
          if (!value) return true;
          return (
            // !dayjs().add(3, "month").isAfter(value) &&
            !dayjs().add(6, "month").isBefore(value)
          );
        }
      ),
    otherwise: () => yup.string().nullable(),
  }),
  confirm: yup.string().optional().nullable(),
  reason: yup.string().optional().nullable(),
});

export function CarrierPaymentModalValidation(values: any) {
  return handleErrorMeg(values, CarrierPaymentModalValidationSchema);
}

export const createClaimValidationSchema = yup.object().shape({
  claim_number: yup.string().required("Claim number is required"),
  date_of_loss: yup.date().required("Date of loss is required"),
  date_reported: yup
    .date()
    .min(
      yup.ref("date_of_loss"),
      "Reported date must be after the Date of loss"
    )
    .required("Reported date is required"),
  other_parties_insurance_company: yup
    .object()
    .required("Other Parties Insurance Company is required")
    .typeError("Other Parties Insurance Company is required"),
  other_parties_policy: yup.string().nullable().optional(),
  other_parties_claim: yup.string().nullable().optional(),
  type_of_loss: yup.string().nullable().optional(),
  description: yup.string().nullable().optional(),
  cat_number: yup.string().nullable().optional(),
  status: yup.string().required(),
  attorney: yup.string().nullable().optional(),
  amount: yup.number().required("Amount is required"),
  path: yup.string().required("File is required").typeError("File is required"),
  file_type: yup
    .string()
    .required("File Type is required")
    .typeError("File Type is required"),
});

export function createClaimValidation(values: any) {
  return handleErrorMeg(values, createClaimValidationSchema);
}

export let CommissionModalValidationSchema = yup.object().shape({
  commission_percentage: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .max(100, "Commission due percentage must be less than or equal to 100")
    .required("Commission percentage is required"),
  minimum_commission_amount: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .required("Minimum commission amount is required"),
});
// .test({
//   name: "at-least-one-required",
//   message:
//     "At least one of commission percentage or minimum commission amount is required",
//   test: function (value) {
//     const { commission_percentage, minimum_commission_amount } = value;
//     if (
//       commission_percentage === null &&
//       minimum_commission_amount === null
//     ) {
//       throw this.createError({
//         path: "common_error", // Specify the key for the error
//         message:
//           "At least one of commission percentage or minimum commission amount is required",
//       });
//     }
//     return true; // Return true if the test passes
//   },
// });

export function commissionModalValidation(values: any) {
  return handleErrorMeg(values, CommissionModalValidationSchema);
}
export const createCoverageValidationSchema = yup.object().shape({
  /*Add Coverage validations*/
  type_of_change: yup.string().required("Type is required"),
  premium: yup.string().required("Premium is required"),
  number_of_payments: yup.string().required("Number of payment is required"),
  premium_deposite: yup
    .string()
    .optional()
    .nullable()
    .test(
      "IsLessThanPremium",
      "Premium deposite must be less than premium",
      function (value: any) {
        const { premium } = this.parent;
        return !(parseInt(premium) < parseInt(value));
      }
    ),
  monthly_deposit: yup
    .string()
    .required("Monthly payment is required")
    .test(
      "IsLessThanPremium",
      "Monthly payment must be less than premium",
      function (value: any) {
        const { premium } = this.parent;
        return !(parseInt(premium) < parseInt(value));
      }
    )
    .test(
      "AmountExceeded",
      "Sum of monthly premium and deposite should be less than Premium",
      function (value: any) {
        const { premium, premium_deposite } = this.parent;
        const totalAmount = parseInt(premium || 0);
        const deposite = parseInt(premium_deposite || 0);
        const due = parseInt(value || 0);
        if (deposite + due > totalAmount) {
          return false;
        }
        return true;
      }
    ),
  installment_charge: yup.string().required("Installment Charge is required"),
  start_date: yup.date().required("Start date is required"),
  completionDate: yup
    .date()
    .required("Completion Date is required")
    .test(
      "notBeforeDateLicensed",
      "Completion date can't be before or same as Start Date",
      function (value) {
        const dateLicensed = this.parent.start_date;
        if (!dateLicensed) return true;
        return (
          !dayjs(value).isSame(dateLicensed) &&
          !dayjs(value).isBefore(dateLicensed)
        );
      }
    )
    .typeError("Completion Date is required"),
});

export function createCoverageValidation(values: any) {
  return handleErrorMeg(values, createCoverageValidationSchema);
}

export let CommissionPaymentValidationSchema = yup.object().shape({
  effective_date: yup.date().required("Effective date is required"),
  payment: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .required("Amount is required"),
  check: yup.string().optional(),
  reason: yup.string().optional(),
});

export function commissionPaymentValidation(values: any) {
  return handleErrorMeg(values, CommissionPaymentValidationSchema);
}

const createQuoteCustomerSchema = yup.object().shape({
  prefix: yup.string().nullable(),
  first_name: yup.string().required("First Name is required"),
  middle_name: yup.string().nullable(),
  last_name: yup.string().required("Last Name is required"),
  business_type: yup
    .object()
    .required("Business type is required")
    .typeError("Business type is required"),
  suffix: yup.string().nullable(),
  gender: yup.string().nullable(),
  marital_status: yup.string().optional().nullable(),
  social_security: yup
    .string()
    .required("Social Security Number is required")
    .test("is-valid-ssn", "Invalid ssn", (value) => {
      if (value) {
        return yup.string().matches(SSNPattern).isValidSync(value);
      }
      return true; // Allow empty value
    }),
  dob: yup.date().required("Date of Birth is required"),
  email: yup.string().required("Email is required"),
  mobile: yup.string().required("Phone Number is required"),
  website: yup.string().url().optional().nullable(),
  license_number: yup.string().required("License number is required"),
  license_state: yup
    .object()
    .required("License state is required")
    .typeError("License state is required"),
  license_issue_date: yup.date().required("License Issue date is required"),
  license_exp_date: yup
    .date()
    .min(
      yup.ref("license_issue_date"),
      "License Issue Date must be after the License Expiry Date"
    )
    .required("License Expiry Date is reqiured")
    .typeError("License Expiry Date is required"),
  address_line_one: yup.string().required("Address line one is required"),
  address_line_two: yup.string().nullable(),
  state: yup
    .object()
    .required("State is required")
    .typeError("State is required"),
  city: yup.string().required("City is required"),
  zipcode: yup
    .string()
    .required("Zip Code is required")
    .test("is-valid-zipcode", "Invalid Zip Code", (value) => {
      if (value) {
        return yup.string().matches(usZipRegex).isValidSync(value);
      }
      return true; // Allow empty value
    }),
  address_type: yup.string().required("Address Type is required"),
  // Add more fields as needed
});

export function createQuoteCustomerValidation(values: any) {
  return handleErrorMeg(values, createQuoteCustomerSchema);
}

const createQuoteEndUserSchema = yup.object().shape({
  prefix: yup.string().nullable(),
  first_name: yup.string().required("First Name is required"),
  middle_name: yup.string().nullable(),
  last_name: yup.string().required("Last Name is required"),
  business_type: yup.object().optional().nullable(),
  suffix: yup.string().nullable(),
  gender: yup.string().nullable(),
  marital_status: yup.string().optional().nullable(),
  social_security: yup
    .string()
    .nullable()
    .optional()
    .test("is-valid-ssn", "Invalid ssn", (value) => {
      if (value) {
        return yup.string().matches(SSNPattern).isValidSync(value);
      }
      return true; // Allow empty value
    }),
  dob: yup.date().optional().nullable(),
  email: yup.string().required("Email is required"),
  email_type: yup.string().required("Email type is required"),
  mobile: phoneSchema(true, "Invalid Phone Number").required(
    "Phone Number is required"
  ),
  mobile_type: yup.string().required("Phone Type type is required"),
  website: yup.string().url().optional().nullable(),
  license_number: yup.string().optional().nullable(),
  license_state: yup.object().optional().nullable(),
  license_issue_date: yup.date().optional().nullable(),
  license_exp_date: yup
    .date()
    .min(
      yup.ref("license_issue_date"),
      "License Issue Date must be after the License Expiry Date"
    )
    .optional()
    .nullable(),
  address_line_one: yup.string().optional().nullable(),
  address_line_two: yup.string().nullable(),
  state: yup.object().optional().nullable(),
  city: yup.string().optional().nullable(),
  zipcode: yup
    .string()
    .optional()
    .nullable()
    .test("is-valid-zipcode", "Invalid Zip Code", (value) => {
      if (value) {
        return yup.string().matches(usZipRegex).isValidSync(value);
      }
      return true; // Allow empty value
    }),
  address_type: yup.string().optional().nullable(),
  // Add more fields as needed
});

export function createQuoteEndUserValidation(values: any) {
  return handleErrorMeg(values, createQuoteEndUserSchema);
}

export function MasterTypeValidation(values: any) {
  return handleErrorMeg(values, MasterTypeValidationSchema);
}

export const MasterTypeValidationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .max(50, "Max 50 Characters")
    .required("Master Type is Required"),
  // is_active:yup.boolean()
});

export function CompanyValidation(values: any) {
  return handleErrorMeg(values, CompanyValidationSchema);
}

export const CompanyValidationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .max(50, "Max 50 Characters")
    .required("Feature Name is Required"),
  // is_active:yup.boolean()
});

export function IndustryValidation(values: any) {
  return handleErrorMeg(values, IndustryValidationSchema);
}
export function PlanValidation(values: any) {
  return handleErrorMeg(values, PlanValidationSchema);
}

export const PlanValidationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .max(50, "Max 50 Characters")
    .required("Plan Name is Required"),
  // is_active:yup.boolean()
  duration: yup.string().trim().required("Duration is Required"),
  duration_type: yup.string().trim().required("Duration Type is Required"),
  discounts: yup.string().trim().required("Discount is required"),
  // features:yup.array().required("Features is Required")
  features: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().trim().required('Please select a value.'),
        name: yup.string().trim().required('Please select a name.')
      })
    )
    .min(1, "At least one feature is required")
    .required("Features are Required")
});
export const IndustryValidationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .max(50, "Max 50 Characters")
    .required("Industry Name is Required"),
  // is_active:yup.boolean()
  duration: yup.string().trim().required("Duration is Required"),
  discounts: yup.string().trim().required("Discount is required"),
  // features:yup.array().required("Features is Required")
  features: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().trim().required('Please select a value.'),
        name: yup.string().trim().required('Please select a name.')
      })
    )
    .min(1, "At least one feature is required")
    .required("Features are Required")
});

export function MasterOptionsValidation(values: any) {
  return handleErrorMeg(values, MasterOptionsValidationSchema);
}

export const MasterOptionsValidationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .max(50, "Max 50 Characters")
    .required("Options is required")
    .typeError("Options is required"),
  code: yup.string().trim().max(50, "Max 50 Characters").nullable().optional(),
  // type: yup
  // .object()
  // .required('Master Type is Required')
});

const createQuotationDriverSchema = yup.object().shape({
  same_as_applicant: yup.boolean().optional(),
  prefix: yup.string().required("Prefix is required"),
  first_name: yup.string().required("First name is required"),
  middle_name: yup.string().nullable().optional(),
  last_name: yup.string().required("Last name is required"),
  suffix: yup.string().nullable().optional(),
  gender: yup.string().required("Gender is required"),
  dob: yup.date().required("Date of Birth is required"),
  marital_status: yup.string().required("Marital status is required"),
  email: yup.string().required("Email is required"),
  occupation: yup.string().required("Occupation is required"),
  //driver_status: yup.string().required("Driver status is required"),
  military_status: yup.string().required("Military status is required"),
  //is_licensed_driver: yup.string().required("Licensed driver is required"),
  date_licensed: yup
    .date()
    .required("Date licensed is required")
    .max(new Date(), "Date licensed cannot be in the future"),
  license_exp_date: yup
    .date()
    .required("License Expiry Date is required")
    .test(
      "SameDate",
      "License Expiry Date and Date of licensed must not be equal",
      function (value) {
        const { date_licensed } = this.parent;
        return !dayjs(value).isSame(date_licensed);
      }
    )
    .min(
      yup.ref("date_licensed"),
      "License Expiry Date can't be before Date of Licensed"
    ),
  license_number: yup.string().required("License number is required"),
  state_licensed: yup
    .object()
    .required("Licensed State is required")
    .typeError("Licensed State is required"),
  tlc_license_number: yup.string().required("TLC License number is required"),

  tlc_effective_date: yup
    .date()
    .required("TLC Effective Date is required")
    .max(new Date(), "TLC Effective Date cannot be in the future"),
  tlc_exp_date: yup
    .date()
    .required("TLC Expiry Date is required")
    .test(
      "SameDate",
      "TLC Expiry Date and TLC Effective Date must not be equal",
      function (value) {
        const { date_licensed } = this.parent;
        return !dayjs(value).isSame(date_licensed);
      }
    )
    .min(
      yup.ref("tlc_effective_date"),
      "TLC Expiry Date can't be before TLC Effective Date"
    ),

  hack_license_number: yup.string().required("Hack License number is required"),
  hack_effective_date: yup
    .date()
    .required("Hack Effective Date is required")
    .max(new Date(), "Hack Effective Date cannot be in the future"),
  hack_exp_date: yup
    .date()
    .required("Hack Expiry Date is required")
    .test(
      "SameDate",
      "Hack Expiry Date and Hack Effective Date must not be equal",
      function (value) {
        const { date_licensed } = this.parent;
        return !dayjs(value).isSame(date_licensed);
      }
    )
    .min(
      yup.ref("hack_effective_date"),
      "Hack Expiry Date can't be before Hack Effective Date"
    ),

  website: yup.string().url().optional().nullable(),
  mobile: yup.string().required("Phone Number is required"),
  // address: yup.string().required("Address is required"),
  total_accidental_conviction_point: yup
    .string()
    .required("Total Accidental Conviction point required"),
  address_type: yup.string().required("Address type is required"),
  address_line_one: yup.string().required("Address line one is required"),
  address_line_two: yup.string().nullable(),
  social_security: yup
    .string()
    .required("Social Security Number is required")
    .test("is-valid-ssn", "Invalid ssn", (value) => {
      if (value) {
        return yup.string().matches(SSNPattern).isValidSync(value);
      }
      return true; // Allow empty value
    }),
  state: yup
    .object()
    .required("State is required")
    .typeError("State is required"),
  city: yup.string().required("City is required"),
  zipcode: yup
    .string()
    .required("Zip Code is required")
    .test("is-valid-zipcode", "Invalid Zip Code", (value) => {
      if (value) {
        return yup.string().matches(usZipRegex).isValidSync(value);
      }
      return true; // Allow empty value
    }),
});

export function createQuotationDriverValidation(values: any) {
  return handleErrorMeg(values, createQuotationDriverSchema);
}

export function CreateQuotationVehicleBasicValidation(values: any) {
  return handleErrorMeg(values, createQuotationVehicleSchema);
}

export const createQuotationVehicleSchema = yup.object().shape({
  // endorsement_date: yup
  //   .date()
  //   .required("Endorsement Date is required")
  //   .typeError("Endorsement Date is required"),
  vin: vinSchema(true, "Invalid vin").required("Vin is required"),
  model: yup.string().required("model is required"),
  make: yup.string().required("Make is required"),
  // model: yup.string().required("Model is required"),
  year: yup.string().required("Year is required").typeError("Year is required"),
  number_plate: yup.string().required("Number Plate is required"),
  shl_permit: yup.string().required("SHL Permit is required"),
  state_registered: yup
    .object()
    .required("State Registered is required")
    .typeError("State Registered is required"),
  body_type: yup
    .string()
    .required("Body Type is required")
    .typeError("Body Type is required"),
  vehicle_usage: yup
    .string()
    .required("Vehicle Usage is required")
    .typeError("Vehicle Usage is required"),
  performance: yup
    .string()
    .required("Performance is required")
    .typeError("Performance is required"),
  territory: yup
    .string()
    .required("Territory is required")
    .typeError("Territory is required"),
  comp_symbol: yup
    .string()
    .required("Comp Symbol is required")
    .typeError("Comp Symbol is required"),
  collison_symbol: yup
    .string()
    .required("Collision Symbol is required")
    .typeError("Collision Symbol is required"),
  cylinders: yup
    .string()
    .required("Cylinders is required")
    .typeError("Cylinders is required"),
  horse_power: yup.string().required("Horse Power is required"),
  fuel_type: yup.string().required("Fuel Type is required"),
  purchase_date: yup
    .date()
    .required("Purchase Date is required")
    .typeError("Purchase Date is required"),
  class_code: yup
    .string()
    .required("Class Code is required")
    .typeError("Class Code is required"),
  seating_capacity: yup.number().required("Seating Capacity is required").typeError("Seating capacity must be a number"),
  reg_exp_date: yup
    .date()
    .required("Reg Exp Date is required")
    .typeError("Reg Exp Date is required"),
  diamond_exp_date: yup
    .date()
    .required("Diamond Exp Date is required")
    .typeError("Diamond Exp Date is required"),
  odometer: yup.string().required("Odameter is required"),
  vehicle: yup
    .string()
    .required("Vehicle is required")
    .typeError("Vehicle is required"),
  zip_code: yup
    .string()
    .matches(usZipRegex, "Invalid Zip Code")
    .required("Zipcode is required"),
  weight: yup.string().nullable().optional(),
  safety_rating: yup.string().nullable().optional(),
  fhv_vehicle_license_number: yup.string().nullable().optional(),
  dmv_license_plate_number: yup.string().nullable().optional(),
  vehicle_permit_license_number: yup.string().nullable().optional(),
  driver_cam: yup.string().nullable().optional(),
  black_box: yup.string().nullable().optional(),
  navigation_gps: yup.string().nullable().optional(),
  bluetooth_hands_free: yup.string().nullable().optional(),
  current_auto_insurance: yup.string().nullable().optional(),
  base_name: yup.string().nullable().optional(),
  automatic_breaking: yup.string().nullable().optional(),
  lane_departure_control: yup.string().nullable().optional(),
  forward_collision_warning: yup.string().nullable().optional(),
  lane_departure_warning: yup.string().nullable().optional(),
  anti_lock_braking_system: yup.string().nullable().optional(),
  electronic_stability_control: yup.string().nullable().optional(),
  traction_control: yup.string().nullable().optional(),
  tyre_pressure_monitoring: yup.string().nullable().optional(),
  automatic_pedestrian_alerts: yup.string().nullable().optional(),
  automatic_crash_notification: yup.string().nullable().optional(),
  blind_spot_warning: yup.string().nullable().optional(),
  event_data_recorder: yup.string().nullable().optional(),
  wheel_chair_accessibility: yup.string().nullable().optional(),
});

export function createQuotationPolicyValidation(values: any) {
  return handleErrorMeg(values, createQuotationPolicyScheduleValidationSchema);
}

export let createQuotationPolicyScheduleValidationSchema = yup.object().shape({
  policy_state: yup
    .object()
    .required("State is required")
    .typeError("State is required"),
  insurance_company: yup
    .object()
    // .required("Insurance Company is required")
    // .typeError("Insurance Company is required"),
    .optional()
    .nullable(),
  policy_type: yup
    .string()
    .required("Policy Type is required")
    .typeError("Policy Type is required"),
  policy_term: yup
    .string()
    .required("Policy Term is required")
    .typeError("Policy Term is required"),
  premium: yup.string().required("Premium is required"),
  effective_date: yup.date().required("Effective Date is required"),
  expiration_date: yup
    .date()
    .min(
      yup.ref("effective_date"),
      "Expiration Date must be after the Effective Date and not before today"
    )
    .required("Expiration Date is required")
    .typeError("Expiration Date is required"),
  carrier_code: yup
    .object()
    .required("Carrier is required")
    .typeError("Carrier is required"),
  product_code: yup
    .object()
    .required("Product code is required")
    .typeError("Product code is required"),
  sub_product_code: yup
    .object()
    .required("Sub product code is required")
    .typeError("Sub product code is required"),
  classification: yup
    .object()
    .required("Classification is required")
    .typeError("Classification is required"),
  underwriting_code: yup
    .object()
    .required("Underwriting code is required")
    .typeError("Underwriting code is required"),
  inception_date: yup
    .date()
    .required("Inception date is required")
    .typeError("Inception date is required"),
  producer_code: yup
    .object()
    .required("Producer code is required")
    .typeError("Producer code is required"),
  payment_plan_code: yup
    .object()
    .required("Payment plan code is required")
    .typeError("Payment plan code is required"),
  creator_code: yup
    .object()
    .required("Creator code is required")
    .typeError("Creator code is required"),
});
export function CreateQuotationDocumentsValidation(values: any) {
  return handleErrorMeg(values, createQuotationDocumentsValidationSchema);
}

export const createQuotationDocumentsValidationSchema = yup.object().shape({
  documents: yup.array().of(
    yup.object().shape({
      name: yup.string(),
      path: yup.string().when("name", {
        is: (value: any) => value == "Driver's License",
        then: () => yup.string().required("License is required"),
        otherwise: () => yup.string().nullable().optional(),
      }),
      file_type: yup.string().when("name", {
        is: (value: any) => value == "Driver's License",
        then: () => yup.string().required("License is required"),
        otherwise: () => yup.string().nullable().optional(),
      }),
      // Additional Info End
    })
  ),
});

export const importModalValidationSchema = yup.object().shape({
  file: yup.mixed().required("File is required"),
});

export function importModalValidation(values: any) {
  return handleErrorMeg(values, importModalValidationSchema);
}

const createEmployeeSchema = yup.object().shape({
  first_name: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  email: yup.string().required("Email is required"),
  mobile: phoneSchema(true, "Invalid Mobile Number").required(
    "Mobile Number is required"
  ),
});

export function createEmployeeValidation(values: any) {
  return handleErrorMeg(values, createEmployeeSchema);
}

export function CreateReconciliationValidation(values: any) {
  return handleErrorMeg(values, createReconciliationValidationSchema);
}

export const createReconciliationValidationSchema = yup.object().shape({
  path: yup.string().required("Document is required"),
  file_type: yup.string().required("File Type is required"),
});

export function udpateLandingPageValidation(values: any) {
  return handleErrorMeg(values, udpateLandinPageValidationSchema);
}

export const udpateLandinPageValidationSchema = yup.object().shape({
  path: yup.string().required("Image is required"),
  file_type: yup.string().required("File Type is required"),
  tag_line: yup.string().required("Tag Line is required"),
  header_contact: yup.string().required("Header Contact is required"),
  header_email: yup
    .string()
    .required("Header Email is required")
    .trim()
    .matches(emailRegex, "Invalid email id"),
  banner_heading: yup.string().required("Banner Heading is required"),
  banner_text: yup.string().required("Banner text is required"),
  what_we_do_section: yup
    .string()
    .required("what we can do section is required"),
  address_section: yup.string().required("Address Section is required"),
  license: yup.string().required("License Number is required"),
  testimonials1_content: yup.string().required("Testimonals 1 is required"),
  testimonials2_content: yup.string().required("Testimonals 2 is required"),
  testimonials3_content: yup.string().required("Testimonals 3 is required"),
  testimonials1_avatar: yup.string().required("Avatar 1 is required"),
  testimonials2_avatar: yup.string().required("Avatar 2 is required"),
  testimonials3_avatar: yup.string().required("Avatar 3 is required"),
  testimonials1_company: yup.string().required("Company 1 is required"),
  testimonials2_company: yup.string().required("Company 2 is required"),
  testimonials3_company: yup.string().required("Company 3 is required"),
  theme_color: yup.string().required("Theme color is required"),
  button_background_color: yup.string().required("Button color is required"),
});

export function createQuotationTaxValidation(values: any) {
  return handleErrorMeg(values, createQuotationTaxValidationSchema);
}

export let createQuotationTaxValidationSchema = yup.object().shape({
  tax_id_type_cd: yup
    .object()
    .required("Tax Type is required")
    .typeError("Tax Type is required"),
  tax_id: yup.string().when("tax_id_type_cd", {
    is: (tax: any) => tax?.name == "Tax Identification Number",
    then: () => yup.string().required("Tax ID is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  social_security: yup.string().when("tax_id_type_cd", {
    is: (tax: any) => tax?.name == "Social Security Number",
    then: () =>
      yup
        .string()
        .required("Social Security Number is required")
        .test("is-valid-ssn", "Invalid ssn", (value) => {
          if (value) {
            return yup.string().matches(SSNPattern).isValidSync(value);
          }
          return true; // Allow empty value
        }),
    otherwise: () => yup.string().nullable().optional(),
  }),

  recipients1099: yup.boolean().optional(),

  recipients1099_recieve_date: yup.string().when("recipients1099", {
    is: (val: any) => val,
    then: () =>
      yup
        .date()
        .required("Recipients 1099 Recieve Date is required")
        .typeError("Recipients 1099 Recieve Date is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  recipients1099_date_from: yup.string().when("recipients1099", {
    is: (val: any) => val,
    then: () =>
      yup
        .date()
        .required("Recipients 1099 From Date is required")
        .typeError("Recipients 1099 From Date is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  recipients1099_date_to: yup.string().when("recipients1099", {
    is: (val: any) => val,
    then: () =>
      yup
        .date()
        .min(
          yup.ref("recipients1099_date_from"),
          "To Date must be after the From Date"
        )
        .typeError("Recipients 1099 To Date is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),

  w9_recieved: yup.boolean().optional(),

  w9_recieved_date: yup.string().when("w9_recieved", {
    is: (val: any) => val,
    then: () =>
      yup
        .date()
        .required("W9 Recipients Recieve Date is required")
        .typeError("W9 Recipients Recieve Date is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  w9_recieved_from: yup.string().when("w9_recieved", {
    is: (val: any) => val,
    then: () =>
      yup
        .date()
        .required("W9 Recipients From Date is required")
        .typeError("W9 Recipients From Date is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  w9_recieved_to: yup.string().when("w9_recieved", {
    is: (val: any) => val,
    then: () =>
      yup
        .date()
        .min(yup.ref("w9_recieved_from"), "To Date must be after the From Date")
        .typeError("W9 Recipients To Date is required"),
    otherwise: () => yup.string().nullable().optional(),
  }),
  nsf_restricted: yup.boolean().optional(),
});

export function RemainderValidation(values: any) {
  return handleErrorMeg(values, RemainderValidationSchema);
}

export const RemainderValidationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .max(50, "Max 50 Characters")
    .required("Title is Required"),
});


export let ChequeValidationSchema = yup.object().shape({
  check: yup.string().optional().nullable(),
  amount_in_words: yup.string().optional().nullable(),
  pay_to_order_of: yup.string().optional().nullable(),
  print_draft_date: yup.string().optional().nullable(),
  signature: yup.string().optional().nullable(),
  confirm: yup.string().optional().nullable(),
  reason: yup.string().optional().nullable(),
  cheque_status: yup.string().required('Status is Required').typeError('Status is Required'),
  description: yup.string().optional().nullable(),
  cheque_drop_date: yup
    .date()
    .required("Drop Date is required")
    // .min(yup.ref("print_draft_date"), "Drop Date must be after the Print Draft Date")
    .typeError("Drop Date is required")
});


export function ChequeValidation(values: any) {
  return handleErrorMeg(values, ChequeValidationSchema);
}


export let BrokerCreationValidationSchema = yup.object().shape({
  first_name: yup.string().required("First Name is required").max(30, "Maximum 30 characters"),
  last_name: yup.string().required("Last Name is required").max(30, "Maximum 30 characters"),
  email: yup
    .string()
    .trim()
    .matches(emailRegex, "Invalid Email")
    .required("Email is required"),
  mobile: phoneSchema(true, "Invalid Mobile Number").required(
    "Mobile Number is required"
  ),
  address: yup.string().optional().nullable(),
  zipcode: yup
    .string()
    .matches(usZipRegex, "Invalid Zip Code")
    .required("Zip Code is required"),
  agency_name: yup.string().required("Agency Name is required").max(50, "Maximum 50 characters"),
  domain: yup.string().matches(domainRegex, "Invalid Sub-domain Name").required("Sub-domain Name is required").max(30, "Maximum 30 characters")
});


export let BrokerEnquiryValidationSchema = yup.object().shape({
  first_name: yup.string().required("First Name is required").max(30, "Maximum 30 characters"),
  last_name: yup.string().required("Last Name is required").max(30, "Maximum 30 characters"),
  email: yup
    .string()
    .trim()
    .matches(emailRegex, "Invalid Email")
    .required("Email is required"),
  mobile: phoneSchema(true, "Invalid Mobile Number").required(
    "Mobile Number is required"
  ),
  address: yup.string().optional().nullable(),
  zipcode: yup
    .string()
    .matches(usZipRegex, "Invalid Zip Code")
    .required("Zip Code is required"),
  agency_name: yup.string().required("Agency Name is required").max(50, "Maximum 50 characters"),
  domain: yup.string().matches(domainRegex, "Invalid Sub-domain Name").required("Sub-domain Name is required").max(30, "Maximum 30 characters"),
  dc_percentage:
    yup
      .number()
      .typeError("Debit Card Percentage is required")
      .required("Debit Card Percentage is required")
      .min(0, "Debit Card Percentage cannot be negative")
      .max(100, "Debit Card Percentage must be 100 or less"),

  cc_percentage: yup.number()
    .typeError("Credit Card Percentage is required")
    .required("Credit Card Percentage is required")
    .min(0, "Credit Card Percentage cannot be negative")
    .max(100, "Credit Card Percentage must be 100 or less"),

});


export function brokerEnquiryValidation(values: any) {
  return handleErrorMeg(values, BrokerEnquiryValidationSchema);
}

export function brokerCreationValidation(values: any) {
  return handleErrorMeg(values, BrokerCreationValidationSchema);
}


export let EmployeeAndLocationSchema = yup.object().shape({
  per_employee_amount: yup.string().required("Amount Per Employee is required").max(10, "Maximum 10 characters"),
  per_location_amount: yup.string().required("Amount per Location is required").max(10, "Maximum 10 characters"),
  free_account_employee_count: yup.string().required("Number of Employees allowed for Free Trial is required").max(10, "Maximum 10 characters"),
  free_account_location_count: yup.string().required("Number of Locations allowed for Free Trial is required").max(10, "Maximum 10 characters")
});

export function employeeAndLocationValidation(values: any) {
  return handleErrorMeg(values, EmployeeAndLocationSchema);
}