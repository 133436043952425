import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IAction } from "interface/redux.interface";

const initialState: IAction = {
  selectedTab: "0",
};

export const actionSlice = createSlice({
  name: "action",
  initialState,
  reducers: {
    setTab(state, action: PayloadAction<any>) {
      state.selectedTab = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder.addCase("LOGOUT", (state, action) => {
      return { ...initialState };
    }),
});

export const { setTab } = actionSlice.actions;
export default actionSlice.reducer;
