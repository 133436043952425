import paymentfailed from "../../asset/images/paylater3.jpg";
import { useNavigate } from 'react-router-dom';
import { Box, Toolbar } from '@mui/material';
import Logo from 'ui-component/Logo';
import Loading from "container/loading";

const Paymentfailed = () => {
  const navigate = useNavigate();

  const handleRetry = () => {
    navigate('/'); // Replace '/retry-payment' with the desired route
  };

  return (
    <div>
      <div className='page-not-found'>
        <img 
          src={paymentfailed}
          alt="Payment failed"
        />
        <h1>Payment Failed. Please Try Again Later!</h1>
        {/* <button onClick={handleRetry} className="retry-button">
          Retry Payment
        </button> */}
      </div>
    </div>
  );
}

export default Paymentfailed;
