import { Box, Button, Grid, List, Typography } from '@mui/material'
import FormLayout from 'component/form'
import EnhancedTable from 'component/tables/selectableTable'
import Loading from 'container/loading'
import dayjs from 'dayjs'
import { commonService } from 'forms/helpers/common.service'
import { errorMessage } from 'forms/helpers/function.helper'
import { Failed, Success } from 'forms/helpers/toast.helper'
import WithFileUpload from 'forms/hoc/formfield/FileUpload'
import { dateFormat, shortenFileName } from 'forms/hooks/customHook'
import useForm from 'forms/hooks/useForm'
import { CreateReconciliationValidation } from 'forms/hooks/validateRules'
import MainContainerWrapper from 'layout/wrappers/MainContainerWrapper'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { compareHeadCell } from 'utils/table.utils'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const Reconciliation = () => {
    const [loading, setLoading] = useState(false);
    const [isCompare, setCompare] = useState(false);
    const navigate = useNavigate();
    const [file, setFile] = useState<any>(null);
    const [compareData, setCompareData] = useState<any>([]);
    const formSubmit = () => {
        fileUpload();
    };

    const initialValues = {
        name: "Document",
        path: "",
        file_type: ""
    }

    const {
        errors,
        values,
        handleSubmit,
        setUpdateValue,
        resetForm
    } = useForm(initialValues, formSubmit, CreateReconciliationValidation);

    const handleRemoveFile = () => {
        setUpdateValue("path", '');
        setUpdateValue("file_type", '');
        setFile(null);
    };

    const fileUpload = () => {
        setLoading(true);
        return new Promise((resolve, reject) => {
            let formData = new FormData();
            formData.append("file", file);
            //   formData.append("upload_type", "customer");
            commonService
                .fileUploadService("uploads", formData)
                .then((res: any) => {
                    setLoading(false);
                    if (res?.data) {
                        handleRemoveFile();
                        resetForm();
                        setCompare(true);
                        Success(res?.data?.message)
                        resolve(res.data);
                        navigate("/reconciliation")
                    } else {
                        Failed("Something went wrong");
                        // reject();
                    }
                })
                .catch((err) => {
                    console.log(err)
                    setLoading(false);
                    Failed(errorMessage(err, "Failed to Upload files"));
                    // reject();
                });
        });
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files;
        if (selectedFile?.length) {
            const file = selectedFile[0];
            const fileName = file.name;
            const fileType = file.type;
            const size = file.size;
            const limit: any = process.env.REACT_APP_UPLOAD_LIMIT;
            if (size >= ((+limit || 10) * 1024 * 1024)) {
                return Failed("Size of attachments cannot exceed 10MB");
            }
            if (fileName.endsWith('.xlsx') || fileName.endsWith('.xls') ||
                fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                fileType === 'application/vnd.ms-excel') {
                // fileUpload(selectedFile[0]);
                setUpdateValue("path", fileName);
                setUpdateValue("file_type", fileType);
                setFile(selectedFile[0])
            }
            else {
                handleRemoveFile();
                Failed("Invalid file type. Please select an Excel file.");
            }
        }
    };

    return (
        <MainContainerWrapper
            headingText="Reconciliation"
            px={{ md: isCompare ? 0 : "25%", xs: 0 }}
        >
            <Box sx={{ p: 3 }}>
                <FormLayout
                    handleSubmit={handleSubmit}
                    isBackButton={true}
                    backButtonClick={() => navigate(-1)}
                    submitButtonText="Upload"
                >
                    {loading && <Loading />}
                    <Grid container columnSpacing={2} p={3}>
                        <Grid item xs={12} pt={3}>
                            <WithFileUpload
                                fileAdded={!!values?.path}
                                fileName={shortenFileName(values?.path)}
                                actionText={'Add'}
                                handleRemoveFile={handleRemoveFile}
                                handleFileChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFileChange(e)}
                                errors={errors?.path}
                                fieldName={values?.name}
                                fileType={".xlsx,.xls,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"}
                                helpText={"Excel files only"}
                            />
                        </Grid>
                    </Grid>
                </FormLayout>
            </Box>
            <Box sx={{ p: 2 }}>
                <h3>Mandatory Fields : </h3>
                <Grid>
                    <li>P-ext</li>
                    <li>Policy Number</li>
                    <li>Effective Date</li>
                    <li>Expiration Date</li>
                    <li>Policy Status</li>
                    <li>Under notice date</li>
                    <li>Broker Name</li>
                    <li>Broker Code</li>
                    <li>Flat Can.</li>
                    <li>Insured Name</li>
                    <li>Insured DL State</li>
                    <li>Insured DL No</li>
                    <li>Past Due Balance</li>
                    <li>System</li>
                </Grid>
            </Box>
        </MainContainerWrapper>
    )
}

export default Reconciliation