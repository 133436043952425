import payment from "../../asset/images/payment.png";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Toolbar } from '@mui/material';
import Logo from 'ui-component/Logo';
import Loading from "container/loading";

const Paymentsuccess = () => {
  const navigate = useNavigate();

  // useEffect(() => {
  //   // Set a timeout to redirect after 5 seconds
  //   const timer = setTimeout(() => {
  //     navigate('/'); // Replace '/home' with your desired route
  //   }, 5000);

  //   // Cleanup function to clear the timer if the component is unmounted
  //   return () => clearTimeout(timer);
  // }, [navigate]);

  return (
    <div>
      <div className='payment-success'>
        <img 
          src={payment}
          alt="Payment successful"
        />
        <h1>Payment Success!, You will get Email in few mints. Then You can Login to your Account.</h1>
      </div>
    </div>
  );
}

export default Paymentsuccess;
