import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./localstorage.helper";
import { useAppDispatch, useAppSelector } from "../store/store";
import { commonService } from "forms/helpers/common.service";
import { clearStore } from "utils/redux.utils";
import {
  setMenuItemsRedux,
  setPopupItemsRedux,
  setSubMenuItemsRedux,
  setUserRedux,
} from "store/slice/user.slice";
import menuItems from "utils/menu-items";
import popupItem from "utils/popup-item";
import { Roles } from "helpers/enum.helpers";
import Loader from "ui-component/Loader";

export const AuthContext: any = createContext({});

export const AuthProvider = ({ children }: any) => {
  const [token, setToken] = useLocalStorage(
    "token",
    sessionStorage.getItem("token")
  );
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user.user);
  const dispatch = useAppDispatch();
  // const [loading, setLoading] = useState(true);


  // const getLandingPageDetails = async() => {
  //   try {
  //     const data = await commonService.getServices(`/landing-page/fetch`);
  //     console.log("data", data?.data?.data[0]);
  //     dispatch(setSettings(data?.data?.data[0]));
  //     setLoading(false)
  //   } catch(err) {
  //     console.log("Landing Page Details Fetching Failed", err);
  //   }
  // }

  // const getUser = async () => {
  //   try {
  //     commonService.getServices(`/user/getProfile`).then((res) => {
  //       const resData = res?.data?.data;
  //       if(!resData?.role) resData['role'] ={
  //         name: Roles.Customer
  //       }
  //       console.log(resData);
  //       dispatch(setUserRedux(resData));
  //       dispatch(setSubMenuItemsRedux([]));
  //       dispatch(setPopupItemsRedux(popupItem[resData?.role?.name] || []));
  //       dispatch(setMenuItemsRedux(menuItems[resData?.role?.name] || []));
  //     });
  //   } catch (err) {
  //     console.log("err", err);
  //     clearStore();
  //     setToken(null);
  //     navigate("/login");
  //   }
  // };

  const login = async (data: any) => {
    setToken(data?.token);
    dispatch(setPopupItemsRedux(popupItem["Super Admin"] || []));
    dispatch(setMenuItemsRedux(menuItems["Super Admin"] || []));
    // await getUser();
  };

  const logout = () => {
    clearStore();
    setToken(null);
    navigate("/login", { replace: true });
  };

  const value = useMemo(
    () => ({
      token,
      login,
      logout,
      user,
      // getUser,
      // getLandingPageDetails
    }),
    [token]
  );

  // if(loading) return <Loader />

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth: any = () => {
  return useContext(AuthContext);
};
