import { IPopupItems } from "interface/redux.interface";

export const popupItem: IPopupItems[] = [
  {
    title: "Logout",
    icon: "logout",
    isLogout: true,
  },
];

export const popupCustomer: IPopupItems[] = [
  {
    path: `/customerprofile`,
    title: "Profile",
    icon: "profile",
  },
  {
    path: `/resetPassword`,
    title: "Reset Password",
    icon: "settings",
  },
  {
    title: "Logout",
    icon: "logout",
    isLogout: true,
  },
];